const wording = {
  advanced: {
    de: 'FORTGESCHRITTEN',
    en: 'ADVANCED'
  },
  advancedDesc: {
    de: 'Für Fotografen, die ihre Qualität steigern und große Projekte organisieren wollen',
    en: 'For photographers who want to increase their quality and organize large projects'
  },
  aiFaceRecognition: {
    de: 'KI-Gesichtserkennung',
    en: 'AI Face Recognition'
  },
  autoLogoOnImages: {
    de: 'Automatisches Logo auf Bildern',
    en: 'Automatic Logo on Images'
  },
  basicDesc: {
    de: 'Perfekt für Fotografen, die ihre Reise beginnen und erweitern möchten.',
    en: 'Perfect for photographers who are starting their journey and want to expand.'
  },
  basis: {
    de: 'Basis',
    en: 'Basic'
  },
  calendarIntegration: {
    de: 'Kalender-Integration',
    en: 'Calendar Integration'
  },
  clientRequests: {
    de: 'Kundenanfragen',
    en: 'Client Requests'
  },
  contactForCustomPlan: {
    de: 'Kontaktieren Sie uns für einen maßgeschneiderten Plan, der zu Ihnen passt!',
    en: 'Contact us for a tailored plan!'
  },
  contactUs: {
    de: 'Kontaktieren Sie uns',
    en: 'Contact Us'
  },
  crm: {
    de: 'CRM (Kundenmanagement)',
    en: 'CRM (Customer Management)'
  },
  customDomain: {
    de: 'Eigene Domain',
    en: 'Custom Domain'
  },
  customized: {
    de: 'Individuell',
    en: 'Customized'
  },
  digitalAlbum: {
    de: 'Digitales Album',
    en: 'Digital Album'
  },
  digitalBusinessCard: {
    de: 'Digitale Visitenkarte',
    en: 'Digital Business Card'
  },
  digitalContract: {
    de: 'Digitaler Vertrag',
    en: 'Digital Contract'
  },
  downloadAllImages: {
    de: 'Alle Bilder herunterladen',
    en: 'Download All Images'
  },
  downloadSelectedImages: {
    de: 'Ausgewählte Bilder herunterladen',
    en: 'Download Selected Images'
  },
  freeTrial: {
    de: '30 Tage kostenlos testen',
    en: 'Try Free for 30 Days'
  },
  imageTagging: {
    de: 'Bild-Tagging',
    en: 'Image Tagging'
  },
  iosAndroidBrandedApp: {
    de: 'iOS- & Android-Marken-App',
    en: 'iOS & Android Branded App'
  },
  liveEvent: {
    de: 'Live-Veranstaltung',
    en: 'Live Event'
  },
  liveVideoStreaming: {
    de: 'Live-Video-Streaming',
    en: 'Live Video Streaming'
  },
  mobileApp: {
    de: 'iOS- & Android-Marken-App',
    en: 'iOS & Android Branded App'
  },
  ourstory: {
    de: 'Unsere Geschichte',
    en: 'Our Story'
  },
  plans: {
    de: 'Preispläne',
    en: 'Plans'
  },
  premium: {
    de: 'PREMIUM',
    en: 'PREMIUM'
  },
  premiumDesc: {
    de: 'Für professionelle Fotografen und Unternehmen, die sich abheben möchten.',
    en: 'For professional photographers and businesses who want to stand out.'
  },
  pricing: {
    de: 'Preise',
    en: 'Pricing'
  },
  shop: {
    de: 'Shop',
    en: 'Shop'
  },
  tailoredPlan: {
    de: 'Ein maßgeschneiderter Plan, der auf Ihre Bedürfnisse zugeschnitten ist.',
    en: 'A tailored plan that fits your needs.'
  },
  unlimitedClients: {
    de: 'Unbegrenzte Kunden',
    en: 'Unlimited Clients'
  },
  unlimitedEvents: {
    de: 'Unbegrenzte Veranstaltungen',
    en: 'Unlimited Events'
  },
  unlimitedGallery: {
    de: 'Unbegrenzte Galerie',
    en: 'Unlimited Gallery'
  },
  videosAndReels: {
    de: 'Videos und Reels',
    en: 'Videos and Reels'
  }
};

export default wording;
